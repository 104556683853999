import React, { Fragment } from 'react'


export default class InReviewAppication extends React.Component {
    constructor(props) {
        super(props);
        this.AddAndroidID = this.AddAndroidID.bind(this);
        this.RemoveAndroidID = this.RemoveAndroidID.bind(this);
        this.AddIOSID = this.AddIOSID.bind(this);
        this.RemoveIOSID = this.RemoveIOSID.bind(this);

        this.androidInput = React.createRef();
        this.iosInput = React.createRef();
        this.state = {
            invalidandroid: [],
            invalidios: [],
            isLoading: true
        }
    }

    async componentWillMount() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' }
        };
        let Data = await fetch("https://taptash.com/api/v1/getconfig", requestOptions);
        let JSONData = await Data.json();
        

    

        console.log(JSONData);

        this.setState({
            invalidandroid: JSONData.android_review,
            invalidios: JSONData.ios_review,
            isLoading: false
        })
    }
    async AddAndroidID() {
        let NewData = this.state.invalidandroid;
        NewData.push(this.androidInput.current.value)
        this.androidInput.current.value = "";
        console.log(NewData);
        let mainBody = {
            "index":"android_review",
            "data":NewData
        }
        const requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' },
            body: JSON.stringify(
                mainBody
            )
        };
        let Data = await fetch("https://taptash.com/api/v1/updateconfig", requestOptions);
        this.setState({
            invalidandroid: NewData
        })
        console.log(Data);

    }
    async RemoveAndroidID(i) {
        let NewData = this.state.invalidandroid;
        NewData.splice(i, 1);
        console.log(NewData);
        let mainBody = {
            "index":"android_review",
            "data":NewData
        }
        const requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' },
            body: JSON.stringify(
                mainBody
            )
        };
        let Data = await fetch("https://taptash.com/api/v1/updateconfig", requestOptions);
        this.setState({
            invalidandroid: NewData
        })
        console.log(Data);

    }
    async AddIOSID() {
        let NewData = this.state.invalidios;
        NewData.push(this.iosInput.current.value)
        this.iosInput.current.value = "";
        console.log(NewData);
        let mainBody = {
            "index":"ios_review",
            "data":NewData
        }
        const requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' },
            body: JSON.stringify(
                mainBody
            )
        };
        let Data = await fetch("https://taptash.com/api/v1/updateconfig", requestOptions);
        this.setState({
            invalidios: NewData
        })
        console.log(Data);

    }
    async RemoveIOSID(i) {
        let NewData = this.state.invalidios;
        NewData.splice(i, 1);
        console.log(NewData);
        let mainBody = {
            "index":"ios_review",
            "data":NewData
        }
        const requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' },
            body: JSON.stringify(
                mainBody
            )
        };
        let Data = await fetch("https://taptash.com/api/v1/updateconfig", requestOptions);
        this.setState({
            invalidios: NewData
        })
        console.log(Data);

    }
    render() {
        return (
            (this.state.isLoading) ?
                <div>
                    Loading ..
                </div>
                :
                <div style={{ textAlign: 'center' }}>
                    <h2>In Android Review</h2>

                    {this.state.invalidandroid.map((e, i) => {
                        return (
                            <div style={{ display: 'flex' }} className="Application_Class">
                                <div style={{ flex: '2' }} key={i}>
                                    {e}
                                </div>
                                <div style={{ flex: '1' }}>
                                    <i onClick={() => this.RemoveAndroidID(i)} className="fas fa-window-close"></i>
                                </div>
                            </div>
                        )
                    })}
                    <div>
                        <div className="input-group m-auto" style={{width:'60%'}}>
                            <input type="text" class="form-control" placeholder="Android Application Bundle ID" ref={this.androidInput} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button onClick={this.AddAndroidID} className="btn btn-outline-secondary" type="button">Add New Android</button>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h2>In IOS Review</h2>

                    {this.state.invalidios.map((e, i) => {
                        return (
                            <div key={i} style={{ display: 'flex' }} className="Application_Class">
                                <div style={{ flex: '2' }} key={i}>
                                    {e}
                                </div>
                                <div style={{ flex: '1' }}>
                                    <i onClick={() => this.RemoveIOSID(i)} className="fas fa-window-close"></i>
                                </div>
                            </div>
                        )
                    })}
                     <div>
                        <div className="input-group m-auto" style={{width:'60%'}}>
                            <input type="text" class="form-control" placeholder="Android Application Bundle ID" ref={this.iosInput} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button onClick={this.AddIOSID} className="btn btn-outline-secondary" type="button">Add New IOS</button>
                            </div>
                        </div>
                    </div>

                </div>
        );
    }
}