import React from 'react'

export default class Campaign extends React.Component {
    DummyData = {
        promotion_id: 0,
        promotion_name: "",
        one_link: "",
        google_play: "",
        total_clicks: 0,
        itunes: "",
        landscape_image: "",
        potrait_image: "",
        icon_url: "",
        is_active: 0
    }
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            Data: null,
            showModal: "none",
            Object: this.DummyData,
            action: "New"
        }

    }
    async componentDidMount() {
        this.GetAll();
    }
    async GetAll() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' }
        };
        let Data = await fetch("https://taptash.com/api/v1/getall", requestOptions);
        let JSON = await Data.json();
        JSON = JSON.data;
        this.setState({
            isLoading: false,
            Data: JSON
        })
        console.log(JSON)
    }
    async GetActive() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' }
        };
        let Data = await fetch("https://taptash.com/api/v1/getactive", requestOptions);
        let JSON = await Data.json();
        JSON = JSON.data;
        this.setState({
            isLoading: false,
            Data: JSON
        })
        console.log(JSON)
    }
    modalToggle(status, data) {
        if (this.state.showModal === "none") {
            this.setState({
                showModal: 'block',
                Object: data,
                action: status
            })
        } else {
            this.setState({
                showModal: 'none',
                Object: data,
                action: status
            })
        }
    }

    render() {
        return (this.state.isLoading) ? <div>Loading..</div> :
            <div className="container">

                <div>
                    <button onClick={() => this.GetAll()} type="button" class="btn btn-primary m-1">Get All</button>
                    <button onClick={() => this.GetActive()} type="button" class="btn btn-warning m-1">Get Active</button>
                    <button type="button" onClick={() => this.AddNewCampaign()} class="btn btn-success m-1">Add New</button>
                </div>
                {
                    this.state.Data.map((e, i) => {
                        return (
                            <div>
                                <div className="row m-2" key={i} style={{ backgroundColor: 'aliceblue', color: 'black', textAlign: 'center' }}>
                                    <div className="col">{e.promotion_name}</div>
                                    <div className="col"><a target="_blank" href={e.one_link}> WEB PAGE LINK </a></div>
                                    <div className="col"><a target="_blank" href={e.google_play}> PLAY STORE</a></div>
                                    <div className="col"><a target="_blank" href={e.itunes}> ITUNES</a></div>
                                    <div className="col-2"><img className="img-fluid" src={e.landscape_image} /></div>
                                    <div className="col-2"><img className="img-fluid" src={e.potrait_image} /></div>
                                    <div className="col-2"><img className="img-fluid" src={e.icon_url} /></div>
                                    <div className="col">
                                        <input checked={e.is_active} class="form-check-input" type="checkbox" id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            IsActive
                                        </label>
                                    </div>

                                    <div className="col">
                                        <button onClick={() => this.ModifyCampaign(i)} type="button" class="btn btn-info">Edit</button>
                                    </div>
                                </div>



                            </div>

                        )
                    })
                }
                {this.GetModalWithData()}
            </div>
    }

    GetModalWithData() {
        return (
            <div style={{ display: this.state.showModal }} className="modal" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.action} </h5>
                            <button type="button" onClick={() => this.modalToggle("Close", this.DummyData)} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input onChange={(e) => {
                                var NewObject = this.state.Object
                                NewObject.promotion_name = e.target.value
                                this.setState({
                                    Object: NewObject
                                })
                            }} defaultValue={this.state.Object.promotion_name} type="text" class="form-control" placeholder="Promotion Name" />
                            <input onChange={(e) => {
                                var NewObject = this.state.Object
                                NewObject.one_link = e.target.value
                                this.setState({
                                    Object: NewObject
                                })
                            }} defaultValue={this.state.Object.one_link} type="text" class="form-control" placeholder="One Link" />
                            <input onChange={(e) => {
                                var NewObject = this.state.Object
                                NewObject.google_play = e.target.value
                                this.setState({
                                    Object: NewObject
                                })
                            }} defaultValue={this.state.Object.google_play} type="text" class="form-control" placeholder="Google Play Link" />
                            <input onChange={(e) => {
                                var NewObject = this.state.Object
                                NewObject.itunes = e.target.value
                                this.setState({
                                    Object: NewObject
                                })
                            }} defaultValue={this.state.Object.itunes} type="text" class="form-control" placeholder="Itunes Link" />
                            <input onChange={(e) => {
                                var NewObject = this.state.Object
                                NewObject.landscape_image = e.target.value
                                this.setState({
                                    Object: NewObject
                                })
                            }} defaultValue={this.state.Object.landscape_image} type="text" class="form-control" placeholder="LandScape Image Link" />
                            <input onChange={(e) => {
                                var NewObject = this.state.Object
                                NewObject.potrait_image = e.target.value
                                this.setState({
                                    Object: NewObject
                                })
                            }} defaultValue={this.state.Object.potrait_image} type="text" class="form-control" placeholder="Potrait Image Link" />
                            <input onChange={(e) => {
                                var NewObject = this.state.Object
                                NewObject.icon_url = e.target.value
                                this.setState({
                                    Object: NewObject
                                })
                            }} defaultValue={this.state.Object.icon_url} type="text" class="form-control" placeholder="Icon Image Link" />
                            <label class="form-check-label" >
                                IsActive
                                     <input
                                    onChange={() => {
                                        var NewObject = this.state.Object

                                        { (NewObject.is_active === 1) ? NewObject.is_active = 0 : NewObject.is_active = 1 }
                                        this.setState({
                                            Object: NewObject
                                        })
                                    }}
                                    checked={((this.state.Object.is_active === 1) ? true : false)} type="checkbox" placeholder="IsActive" />
                            </label>

                        </div>
                        <div className="modal-footer">
                            <button onClick={() => this.CallAPI()} type="button" class="btn btn-primary">Save changes</button>
                            <button type="button" onClick={() => this.modalToggle("Close", this.DummyData)} class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async CallAPI() {
        let requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' },
        };
        if (this.state.action === "New") {
            requestOptions = {
                crossDomain: true,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' },
                body: JSON.stringify(this.state.Object)
            };
            console.log(this.state.Object);
            let Data = await fetch("https://taptash.com/api/v1/addnewpromotion", requestOptions)
            console.log(Data);
            this.modalToggle("Saved", this.DummyData);
            await this.GetAll();

        } else if (this.state.action === "Update") {
            requestOptions = {
                crossDomain: true,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' },
                body: JSON.stringify(this.state.Object)

            };
            console.log(this.state.Object);
            let Data = await fetch("https://taptash.com/api/v1/updatepromotion", requestOptions)
            this.modalToggle("Saved", this.DummyData);
            await this.GetAll();
        }

    }
    async ModifyCampaign(id) {
        let Data = this.state.Data[id]
        console.log("Data to change ", Data);
        this.modalToggle("Update", Data);
    }
    async AddNewCampaign() {
        let Data = this.DummyData;
        this.modalToggle("New", Data);
    }
}
