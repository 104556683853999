import logo from './logo.svg';
import './App.css';
import MainLayout from './Layouts/MainLayout';
import InvalidApplication from './Components/InvalidApplication';
import InReviewAppication from './Components/InReviewApplication';
import {
  BrowserRouter as Router, Switch, Route,
} from "react-router-dom";
import Organization from './Components/Organiztion';
import Campaign from './Components/Campaign';
function App() {
  return (
    <Router>
      <MainLayout>

        <Switch>
        <Route exact={true} path="/">
            <Campaign/>
          </Route>
          <Route exact={true} path="/review">
            <InReviewAppication />
          </Route>
          <Route exact={true} path="/invalid">
            <InvalidApplication />
          </Route>
          <Route exact={true} path="/organization">
            <Organization/>
          </Route>


        </Switch>

      </MainLayout>
    </Router>
  );
}

export default App;
