import React from 'react'


export default class Organization extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            status: [],
            isLoading: false
        }
    }

    async componentDidMount() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' }
        };
        let Data = await fetch("https://taptash.com/api/v1/getconfig", requestOptions);
        let JSONData = await Data.json();

        this.setState({
            status: JSONData.organization,
            isLoading: false
        })
    }

    async ChangeStatus(val){
        console.log(val);
        let NewStatus = this.state.status;
        NewStatus[val] = !NewStatus[val]

        let mainBody = {
            "index":"organization",
            "data":NewStatus
        }
        const requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Api-Key': '878ecc18-7fda-43ef-b141-b4e773668878' },
            body: JSON.stringify(
                mainBody
            )
        };
        let Data = await fetch("https://taptash.com/api/v1/updateconfig", requestOptions);
        this.setState({
            status: NewStatus
        })

    }

    render() {
        return (
            (this.state.isLoading) ? <div>Loading..</div> :
                <div>
                    <div className="container">

                        <div className="row">
                            <div class="form-check form-switch">
                                <input class="form-check-input" onClick={()=>this.ChangeStatus(0)} checked={this.state.status[0]} type="checkbox" id="flexSwitchCheckDefault" />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Pen And Web</label>
                            </div>
                        </div>

                        <div className="row">
                            <div class="form-check form-switch">
                                <input class="form-check-input" onClick={()=>this.ChangeStatus(1)} checked={this.state.status[1]} type="checkbox" id="flexSwitchCheckDefault" />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Xaavia</label>
                            </div>
                        </div>

                        <div className="row">
                            <div class="form-check form-switch">
                                <input class="form-check-input" onClick={()=>this.ChangeStatus(2)} checked={this.state.status[2]} type="checkbox" id="flexSwitchCheckDefault" />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Oh Games</label>
                            </div>
                        </div>

                        <div className="row">
                            <div class="form-check form-switch">
                                <input class="form-check-input" onClick={()=>this.ChangeStatus(3)} checked={this.state.status[3]} type="checkbox" id="flexSwitchCheckDefault" />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Draco Arts</label>
                            </div>
                        </div>

                   

                    </div>
                </div>
        )
    }
}