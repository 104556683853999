import React from 'react'
import NavBar from '../Components/NavBar'

export default class MainLayout extends React.Component {
    render() {
        return (
            <div className="main-layout">
                <div className="main-nav">
                    <NavBar />
                </div>
                <div className="main-child">
                    {this.props.children}
                </div>
            </div>
        )
    }
}