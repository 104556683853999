import React from 'react'

import {
    Link
} from "react-router-dom";
export default class NavBar extends React.Component {

    render() {
        return (

            <ul>
                <li>
                    <Link to="/">Campaigns</Link>
                </li>
                <li>
                    <Link to="/review">In Review Applications</Link>
                </li>
                <li>
                    <Link to="/invalid">On Promotion Application</Link>
                </li>
                <li>
                    <Link to="/organization">Organization</Link>
                </li>
            </ul>

        );
    }
}